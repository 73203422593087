import { Button } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Company, Salutation } from '../../model';
import { AttachEmail } from "@mui/icons-material";

export const MailToButton: VoidFunctionComponent<{ membership: string, company: Company, filename: string }> = ({
                                                                                                                  membership,
                                                                                                                  company,
                                                                                                                  filename
                                                                                                                }) => {
  const { t } = useTranslation();
  const newLine = '%0D%0A';
  const salutation = company.responsiblePerson ? `${company.responsiblePerson.salutation && company.responsiblePerson.salutation == Salutation.Female ? 'Sehr geehrte Frau' : (company.responsiblePerson.salutation == Salutation.Male) ? 'Sehr geehrter Herr' : ''} ${company.responsiblePerson.lastName}` : 'Sehr geehrte Damen und Herren';
  const emailTo = company.responsiblePerson?.companies?.find(c => c.company.id === company.id)?.email || company.responsiblePerson?.email;

  const bodyText = `leider haben wir auf unser Email vom xy keine Rückmeldung erhalten.${newLine}`;
  const bodyText3 = `Wir dürfen Sie nochmals um Bestätigung ersuchen, dass die von uns übermittelte Liste der Presseausweis-Inhaberinnen und Inhaber aktuell ist und die genannten Personen die Kriterien für den Bezug eines Presseausweises erfüllen.${newLine}${newLine}${filename}${newLine}${newLine}`;
  const bodyText4 = `Bitte beachten Sie die Rückmeldefrist bis 25. Februar 2025. Sollten wir bis dahin keine Rückmeldung von Ihnen erhalten, verlieren die Ausweise ihre Gültigkeit und es erfolgt eine Meldung an den Vorstand des ÖZV.${newLine}${newLine}`;
  const bodyFooter = `Wir danken schon jetzt herzlich für Ihre Kooperation und stehen für Fragen gerne zur Verfügung,${newLine}${newLine}liebe Grüße${newLine}Nadja Vaskovich`;
  return (
    <>
      {company && (
        <Button
          color="primary"
          variant="contained"
          target="_blank"
          href={`mailto:${emailTo ? emailTo : ''}?subject=Reminder: Abfrage zur Aktualität der Presseausweis-Inhabenden und ihrer Bezugsberechtigung – Rückmeldefrist bis 25. Februar! - ${encodeURIComponent(company.name)}&body=${salutation},${newLine}${newLine}${bodyText}${bodyText3}${bodyText4}${bodyFooter}`}
          startIcon={<AttachEmail/>}
        >
          {t('Senden ('.concat(membership).concat(')'))}
        </Button>
      )}
    </>
  );
};
